import React from "react";
import PropTypes from "prop-types";
import { errorType } from "../../types";

const GraphQLErrorList = ({ errors }) => (
  <div>
    <h1>GraphQL Error</h1>
    {errors.map((error) => (
      <pre key={error.message}>{error.message}</pre>
    ))}
  </div>
);

GraphQLErrorList.defaultProps = {
  errors: null,
};

GraphQLErrorList.propTypes = {
  errors: PropTypes.arrayOf(errorType),
};

export default GraphQLErrorList;
