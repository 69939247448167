import React from "react";
import PropTypes from "prop-types";

const Image = ({
  className,
  onEnter,
  onLeave,
  src,
  alt,
  height,
  width,
  style,
}) => {
  return (
    <img
      className={className}
      style={{ ...style }}
      src={src}
      alt={alt}
      height={height}
      width={width}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    />
  );
};

Image.defaultProps = {
  className: "",
  alt: "",
  width: null,
  height: null,
  onEnter: () => {},
  onLeave: () => {},
  style: null,
};

Image.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onEnter: PropTypes.func,
  onLeave: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default Image;
