import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../components/Layout/Layout";
import Section from "../components/Section/Section";
import SEO from "../components/SEO";
import PortableText from "../components/PortableText";
import GraphQLErrorList from "../components/GraphQLErrorList";
import { errorType } from "../types";

export const query = graphql`
  query AboutPageQuery {
    sanityPage(slug: { current: { eq: "about" } }) {
      _rawContent
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2, 3),
  },
}));

const AboutPage = ({ data, errors }) => {
  // eslint-disable-next-line no-underscore-dangle
  const content = data && data.sanityPage && data.sanityPage._rawContent;
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  const classes = useStyles();
  return (
    <Layout>
      <SEO title="About" />
      <Section heading="About">
        <Paper className={classes.content} elevation={4}>
          <PortableText blocks={content} />
        </Paper>
      </Section>
    </Layout>
  );
};

AboutPage.defaultProps = {
  errors: null,
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    sanityPage: PropTypes.shape({
      _rawContent: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
  errors: PropTypes.arrayOf(errorType),
};

export default AboutPage;
